<template>
    <div class="m-pop">
        <div class="pop-con" style="max-width: 600px;">
            <div class="title">
                <h3>选择收货地址</h3>
                <div class="close" @click="$emit('ConfirmpickAddress')"></div>
            </div>
            <div class="inner-con">
                <div class="address-list">
                    <el-radio-group v-model="radio">
                        <div class="item" v-for="(item,index) in addressList" :key="index">
                            <div class="radio">
                                <el-radio :label="index">
                                    <p>{{ item.username }}，{{ item.provinceName }}，{{ item.cityName }}，{{ item.districtName }}{{ item.address }}，{{ item.phone }}</p>
                                </el-radio>
                            </div>
                            <div class="tools">
                                <span class="blue" @click="clickedit(item)">编辑</span>
                                <span @click="showDel = true,addressId=item.id" class="red">删除</span>
                            </div>
                        </div>
                    </el-radio-group>
                </div>
                <div class="add-address" @click="iseditgoodsAddress=false,showAdd = true">新增收货地址</div>
                <div class="g-btn small center"  @click="ConfirmpickAddress">确定</div>
            </div>
        </div>
        <delPop v-if="showDel" @close="showDel = false" @confirm="deleteAddress"/>

        <addAddress :addressLists="addressList" :provincelist="provincelist" :goodsAddressInfo="goodsAddressInfo" :isedit="iseditgoodsAddress" v-if="showAdd" @getaddressList="$emit('getaddressList')" @close="showAdd = false" />

    </div>
</template>

<script>
import delPop from "@/components/delPop.vue";
import addAddress from "@/components/addAddress.vue";
import {Addressdelete, addressList} from "@/api/account";
export default {
    name: "pickAddress",
    components:{delPop,addAddress},
    props:['addressList','addressid','provincelist','JFradio'],
    data() {
        return {
            radio: 1,
            showDel:false,
            showAdd:false,
            addressId:"", // 地址id
            iseditgoodsAddress:false,  // 是否编辑收货地址
            goodsAddressInfo:"", // 点击编辑后的地址信息
        }
    },
    watch:{
        JFradio(n,o){
            this.radio=n
        },
	    addressList: {
		    handler (newValue) {
			    console.log(newValue, 'newValue obj')
			    this.addressList.forEach((i,index)=>{
				    if(i.id==this.addressid){
					    this.radio=index
				    }
			    })
		    },
		    deep: true // 深度监听
	    }
    },
    mounted() {
        this.addressList.forEach((i,index)=>{
            if(i.id==this.addressid){
                this.radio=index
            }
        })

    },
    methods:{
        clickedit(item){
            this.iseditgoodsAddress=true
            this.showAdd = true
            this.goodsAddressInfo=item
        },
        // 确认选择地址
        ConfirmpickAddress(){
            console.log(this.addressList[this.radio])
            this.$emit('ConfirmpickAddress',this.addressList[this.radio])
        },

        // 删除收货地址
        deleteAddress(){
            console.log(this.addressId)
            Addressdelete({ids:this.addressId}).then((res) => {
                if (res.data.code==200) {
                    this.$message({
                        type: 'success',
                        message: "删除成功",
                        duration: 2000
                    });
                    this.showDel=false
                }else{
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        duration: 2000
                    });
                }
	            this.$emit('getaddressList') // 重新获取地址列表
                this.addressId=''

            })
        },

    }
}
</script>

<style scoped>

</style>
